@import '../variables/variables.scss';

/** NORMALIZE CSS **/
html {line-height: 1.15; -webkit-text-size-adjust: 100%;}
// body {margin: 0;}
main {display: block;}
h1 {font-size: 2em; margin: 0.67em 0;}
hr {box-sizing: content-box; height: 0; overflow: visible;}
pre {font-family: monospace, monospace; font-size: 1em;}
a {background-color: transparent;}
abbr[title] {border-bottom: none;  text-decoration: underline; text-decoration: underline dotted;}
b, strong {font-weight: bolder;}
code, kbd, samp {font-family: monospace, monospace; font-size: 1em;}
small {font-size: 80%;}
sub, sup {font-size: 75%; line-height: 0; position: relative; vertical-align: baseline;}
sub {bottom: -0.25em;}
sup {top: -0.5em;}
img {border-style: none;}
button, input, optgroup, select, textarea {font-family: inherit; font-size: 100%; line-height: 1.15; margin: 0;}
button, input {overflow: visible;}
button, select {text-transform: none;}
button, [type="button"], [type="reset"], [type="submit"] {-webkit-appearance: button;}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {border-style: none; padding: 0;}
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {outline: 1px dotted ButtonText;}
fieldset {padding: 0.35em 0.75em 0.625em;}
legend {box-sizing: border-box; color: inherit; display: table; max-width: 100%; padding: 0; /* 3 */ white-space: normal;}
progress {vertical-align: baseline;}
textarea {overflow: auto;}
[type="checkbox"], [type="radio"] {box-sizing: border-box; padding: 0;}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {height: auto;}
[type="search"] {-webkit-appearance: textfield; outline-offset: -2px;}
[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
::-webkit-file-upload-button {-webkit-appearance: button; font: inherit;}
details {display: block;}
summary {display: list-item;}
template {display: none;}
[hidden] {display: none;}

/** GLOBAL **/
// * {cursor: none;}
*, *:hover {cursor: none;}
body {margin: 0 auto; cursor: none!important;}
input, textarea, input[type="submit"] {font-family: "Font Awesome 5 Free";}
:focus, :hover {outline: 0;}
.anchor { padding-top: 100px; margin-top: -100px;}
a, a:hover {transition: .8s;}

/** SVG ANIMATED LAYER **/
#calque-svg-animated {width: 100%; height: 100%; z-index: 99; background: transparent; position: relative;}

/** FONT STYLE **/
.italic {font-style: italic;}
.bold {font-weight: bold;}
.underline {text-decoration: underline;}
.uppercase {text-transform: uppercase;}
.internal-link {color: #ff7f2b; font-weight: 700; text-decoration: none;}
.internal-link:hover {text-decoration: underline;}

/** FRONT BUTTON **/

/** PAGE HEADER **/
.page-header { display: flex; justify-content: center; height: 150px;}
.page-header h1 { display: inline-block; position: relative; font-family: $abril; font-weight: 400; line-height: 1.2; font-size: 80px; margin: 50px 0px auto;}


/** BUTTON LEARN MORE **/
@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {transition: $property $duration $ease;}

* {
    &::before, &::after {box-sizing: border-box;}
}

button {position: relative; display: inline-block; cursor: pointer; outline: none; border: 0; vertical-align: middle; text-decoration: none; background: transparent; padding: 0; font-size: inherit; font-family: inherit;
    &.learn-more {width: 220px; height: auto;}
        .circle {@include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1)); position: relative; display: block; margin: 0; width: 3rem; height: 3rem; background: #282936; border-radius: 1.625rem;}
        .icon {@include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1)); position: absolute; top: 0; bottom: 0; margin: auto; background: $white;
            &.arrow {@include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1)); left: 0.625rem; width: 1.125rem; height: 0.125rem; background: none;}
            &::before {position: absolute; content: ''; top: -0.25rem; right: 0.0625rem; width: 0.625rem; height: 0.625rem; border-top: 0.125rem solid $white; border-right: 0.125rem solid $white; transform: rotate(45deg);}
        }
    .button-text {font-family: $montserrat; font-weight: 700; @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1)); position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 0.75rem 0; margin: 0px 0 0 40px; color: #282936; line-height: 1.6; text-align: center; text-transform: uppercase;}

    &:hover {
        .circle {width: 100%;}
        .icon {
            &.arrow {background: $white; transform: translate(1rem, 0);}
        }
        .button-text {color: $white;}
    }
}




/** RESPONSIVE DESIGN **/

@media (max-width: 768px) {
    .page-header h1 {font-size: 50px;}
}
