@import '../base/app.scss';

 /* BANNER */

 #homepage-banner {display: flex; justify-content: center; align-items: center; background: #141414; border-left: 100px solid #141414; border-right: 100px solid #141414; border-bottom: 77px solid #141414; width: calc(100% - 200px); height: calc(91vh - 81px); margin:auto; position: relative;}
 #homepage-video {width: 95%; height: auto; border-radius: 25px; margin-top: 50px;}
 #homepage-title {position: absolute; top: 0; left: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%;}
 #homepage-title h1 {position: absolute; top: 100px; font-family: 'Luckiest Guy', cursive; font-size: 100px;  font-weight: 500; color: $white;}
 #homepage-title span {display: flex; align-items: center; position: absolute; top: 300px; font-family: $oswald; font-size: 62px; color: $white;}
 #homepage-title h2 {font-family: $oswald; font-size: 62px; color: $white; margin: 0px 10px;}
 .banner-logo-cwa {top: 80%; position: absolute; display: flex; justify-content: center; align-items: center; width: 70px; height: 110px; margin-right: -100px; padding-right: 50px; z-index: 3; background-color: $white; right: 0; border-top-left-radius: 50%; border-bottom-left-radius: 50%;}

 /** SVG ANIMATED **/
 #home-block-svg {z-index: 98; position: absolute; display: flex; justify-content: center; align-items: center; padding-left: 60px; height: 70px;}
 #home-block-svg svg#home-cwa {position: absolute; /*transform: translate(-50%, 0%);*/ width: 70px; height: 70px;}
 #home-block-svg svg#home-circle-cwa {position: absolute; width: 77px; height: 77px; /*margin:0px 0 0 -70px;*/ -webkit-animation:spin 15s linear infinite; -moz-animation:spin 15s linear infinite; animation:spin 15s linear infinite;}
 @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
 @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
 @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.banner-logo-cwa svg {height: 70px;}
.banner-logo-cwa svg#home-cwa .st0 {fill:#100C08;}
.banner-logo-cwa svg#home-circle-cwa .st0 {fill:#100C08;}
.progress { z-index: 2; display: flex; justify-content: flex-end; position: absolute; padding-left: 13%; height: 110px; width: 90%; top: 80%;}
.progress-value { animation: load 3s normal forwards; animation-delay: 1.5s; background: #141414; width: 0; padding-left: 30px; padding-top: 45px; }
@keyframes load { 0% { width: 0; } 100% { width: 100%; } }
.line-1{ position: relative; font-family: $oswald; font-size: 24px; letter-spacing: .9px; line-height: 30px; color: $white; text-align: left; white-space: nowrap; overflow: hidden; transform: translateY(-50%); }
.line-1 a { color: $white; text-decoration: none; margin: 0% 5px; }
.line-1 a:hover { color: $yellow; }

/* TYPEWRITING ANIMATION */
.anim-typewriter{
 animation:
   typewriter 4s steps(44) 5s 1 normal both,
   // blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
 from{width: 0;}
 to{width: 100%;}
}
p.line-1.anim-typewriter .strategy:hover {color: $strategy-color;}
p.line-1.anim-typewriter .creation:hover {color: $creation-color;}
p.line-1.anim-typewriter .print:hover {color: $print-color;}
p.line-1.anim-typewriter .identity:hover {color: $identity-color;}
p.line-1.anim-typewriter .seo:hover {color: $seo-color;}
p.line-1.anim-typewriter .community:hover {color:$community-color}


/* EXPERTISES SECTION */

@media screen and (min-width: 1200px) {
.expertises { padding: 66px 0; }
}
.expertises { padding: 42px 0 53px; }
section { display: block; }
@media screen and (min-width: 1200px) {
   .expertises-container { width: 100%; max-width: 1085px; margin: 0 auto; }
}
.expertises-container { padding: 0 15px; }


/* A verifier si tout est bon - START */
@media screen and (min-width: 1200px) {
   .expertises h2 { position: relative; font-family: $abril; font-weight: 400; line-height: 1.2; border-bottom: 1px solid #000; padding-bottom: 30px; margin-bottom: 51px; font-size: 60px; }
}
.expertises h3 { font-family: $oswald; font-weight: 400; line-height: 32px; font-size: 27px; letter-spacing: .9px; min-height: 50px;}
/* Vérifier si tout est bon - END */

.expertises-list { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; width: 90%; margin: 0 auto; }
.expertises-list .item { max-width: none; position: relative; z-index: 1; width: calc(100% - 90px); height: 350px; margin: 0 45px 45px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; }
@media screen and (min-width: 1200px) {
   .expertises-list .item::before { content: ''; position: absolute; top: 0px; left: -20px; right: -40px; bottom: -20px; z-index: -1; /*background-color: $yellow;*/ opacity: 0; -webkit-transition: opacity .3s; transition: opacity .3s; }
   .expertises-list .item.strategy::before {background-color: $strategy-color;}
   .expertises-list .item.creation::before {background-color: $creation-color;}
   .expertises-list .item.print::before {background-color: $print-color;}
   .expertises-list .item.identity::before {background-color: $identity-color;}
   .expertises-list .item.seo::before {background-color: $seo-color;}
   .expertises-list .item.community::before {background-color: $community-color;}
}
@media screen and (min-width: 1200px) {
   .expertises-list .item:hover::before { opacity: 1; }
}
.expertises-list a {width: calc(33.33%); text-decoration: none; color: #000; }
.item a.c-link-dark {height: 30%;}
.item p {display: flex; align-items: flex-start; height: 60%; font-family: $montserrat; }
.item .more { opacity: 0; color: #0c0c0c; font-weight: 600; font-size: 21px; line-height: 30px; border-bottom: 2px solid currentColor; -webkit-transition: opacity .3s; transition: opacity .3s; }
.item:hover .btn span:not(.btn__icon) { opacity: 1; pointer-events: auto; }
.item a.more:hover { color: $white; }
.expertises-list h3 a { border-bottom: 2px solid currentColor; }
@media screen and (min-width: 1200px) {
   .expertises-list p { font-size: 18px; line-height: 26px; margin-bottom: 12px; }
}
.expertises-list p { font-size: 16px; line-height: 24px; height: -webkit-fill-available; }
.btn { font-size: .875rem; padding: 18px 39px; display: inline-block; position: relative; font-weight: 800; text-align: center; text-transform: uppercase; letter-spacing: 0.03; }
.btn span:not(.btn__icon) { font-family: $montserrat; font-weight: 900; transition: transform ease .35s; display: inline-block; opacity: 0; }
.btn .btn__icon { transition: transform ease .35s, opacity ease .35s; }
.btn__icon { right: 39px; }
.btn__icon { transform: translateY(-50%); opacity: 0; }
.btn__icon, .btn__icon svg { width: 17px; height: 10px; }
.btn__icon { position: absolute; top: 50%; }
.btn__icon svg { width: 17px; height: 10px; }
svg { width: 100%; height: auto; display: block; overflow: visible; }
.btn__icon svg * { fill: #000; }
.btn:hover span:not(.btn__icon) { transform: translateX(-11px); }
.btn:hover .btn__icon { transform: translate(15px, -50%); opacity: 1; }
.btn__icon, .btn__icon svg { width: 17px; height: 10px; }
.btn__icon { position: absolute; top: 50%; }

/** A TESTER - START **/
.block-project a img:hover { transform: scale(0.95); transition: transform .5s; }
/** A TESTER - END **
/
/* PROJECT SECTION */
.projects { background-color: #161616; padding: 50px 0px 150px 0px; }
.projects-container { width: 100%; height: auto; max-width: 1085px; margin: 0 auto; }
.projects h2 { position: relative; font-family: $abril; font-weight: 400; line-height: 1.2; padding-bottom: 0px; margin-bottom: 0px; font-size: 60px; }
.projects h3 { position: relative; font-family: $montserrat; line-height: 1.2; border-bottom: 1px solid $white; padding-bottom: 30px; margin-bottom: 51px; font-size: 21px; font-weight: 100; }
.project-intro { display: flex; justify-content: flex-end; width: 33%; margin-left: 55%; margin-bottom: -120px; padding-top: 70px; font-family: $galada; font-size: 7.7244258872651vw; line-height: .88em; letter-spacing: -4.44px; color: $white; }
.projects-grid { display: flex; justify-content: space-around; width: 100%; max-width: 1085px; margin: 0 auto; display: flex; flex-wrap: wrap; margin-top: -5%; }
.projects-grid a .project { height: 600px; width: 470px; border-bottom: 2px solid #F5F5F5; }
.projects-grid .block-project a img {width: 472px; height: 472px;}
a.project img { width: 100%; }
a.project summary { height: 130px; }
.projects-grid a { text-decoration: none; height: 100%; margin-left: 3%; margin-right: 3%; }
.project-hashtag { font-family: $montserrat; color: rgba(255,255,255,.6); padding: 2%; }
.project-title { color: $white; padding: 2%; font-family: $montserrat; text-transform: uppercase; font-size: 24px; font-weight: 600;}
.projects-grid a:hover .project-title { color: $yellow; cursor: none; }
.projects-grid a:hover .project-hashtag { cursor: none; }
.project-2 { padding-top: 25%; margin-bottom: 15%; }
.project-3 { margin-top: -30%; }
.projects-more { width: 100%; max-width: 1085px; margin-top: -10%; padding-bottom: 10%; padding-left: 17%; transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s; }
button.learn-more.home {width: 310px;}
button.learn-more.home .circle.home {background: $yellow;}
button.learn-more.home .circle.home .icon.arrow::before {border-top: 0.125rem solid #000; border-right: 0.125rem solid #000;}
button:hover .circle.home .icon.arrow { background: #000;}
button.learn-more.home .button-text.home {font-family: $galada; letter-spacing: -1.12px; font-size: 36px; line-height: 30px; color: $yellow; text-transform: initial; padding-left: 0px; animation-duration: 1.8s; animation-name: clignoter; animation-iteration-count: infinite; transition: none;}
@keyframes clignoter {
    0%   {opacity:1;}
    50%  {opacity:0;}
    100% {opacity:1;}
}
button.learn-more.home:hover .button-text.home {color: #000; animation-name: none;}

 /* NEWS SECTION */

.news { padding: 5% 0% 5% 0%; }
.news-container { width: 100%; max-width: 1085px; margin: 0 auto; }
.news h2 { position: relative; font-family: $abril; font-weight: 400; line-height: 1.2; padding-bottom: 0px; margin-bottom: 0px; font-size: 60px; }
.news h3 { position: relative; font-family: $montserrat; line-height: 1.2; border-bottom: 1px solid #000; padding-bottom: 30px; margin-bottom: 51px; font-size: 21px; font-weight: 500; }
.related-posts-slider { display: -webkit-box; display: -ms-flexbox; display: flex; justify-content: center; width: 100%; max-width: 1470px; margin: 0 auto -30px; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-bottom: 0; max-width: none; }
.related-posts-slider a.block-news {width: calc(30% + 1px); text-decoration: none;}
.related-posts-slider .item { width: auto; border: solid rgba(12,12,12,.1); border-width: 0 1px; margin-right: -1px; padding: 66px 54px; position: relative; z-index: 1; }
.related-posts-slider .item::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; background-color: #161616; opacity: 0; -webkit-transition: opacity .3s; transition: opacity .3s; }
.related-posts-slider .item:hover::before { opacity: 1; transition: .3s;}
.related-posts-slider .item {font-family: $montserrat;}
.related-posts-slider .item:hover time { color: rgba(255,255,255,.6); }
.related-posts-slider .item:hover .title a { color: $white; }
.related-posts-slider .item:hover .title a:hover { color: $yellow; }
.related-posts-slider .item .category {font-family: $montserrat;}
.related-posts-slider .item:hover .category {color: rgba(255,255,255,.6);}
.related-posts-slider .item { display: block!important; -ms-flex-negative: 0; flex-shrink: 0; color: rgba(12,12,12,.5); font-weight: 500; font-size: 12px; line-height: 22px; border-left: 1px solid #ebebeb; padding: 20px 30px 20px 24px; min-height: 250px; }
.related-posts-slider .title { -webkit-transition: color .3s; transition: color .3s; font-size: 30px; color: #000; font-weight: 600; letter-spacing: -1px; border-bottom: none; height: 40%; }
.related-posts-slider a:hover .title{color: #f4ca02; transition: .3s;}
.related-posts-slider .category {display: flex;}
.related-posts-slider .category-hashtag {margin-right: 5px;}
.news-btn { display: flex; justify-content: flex-end; margin: 0% 10% 3% 0%; align-items: center; height: 100px; margin-top: 30px;}
.news-btn .news-more { background-color: #000; text-align: center; text-decoration: none; font-family: $montserrat; padding: 1.5% 2%; color: $white; display: flex; justify-content: center; align-items: center; line-height: 25px; border: 2px solid #000; }
a.news-more { font-size: 24px; font-weight: bold; }
.news-more:hover { color: #000; }
.slide_right:hover { border: 2px solid $yellow; box-shadow: inset 400px 0 0 0 $yellow; }


/** RESPONSIVE DESIGN **/
   @media (max-width:768px) {
      /** BANNER **/
         #homepage-banner {height: calc(100vh - 140px); overflow: hidden; border-left: none; border-right: none; border-bottom: none; width: 100%;}
         #homepage-video {height: 90vh; width: auto; position: relative; top: -10%; right: 0%; bottom: 0%; left: 0%;}
         #homepage-title {width: 90%; left: 5%;}
         #homepage-title h1 {top: 40px; font-size: 67px; letter-spacing: 5px; line-height: 55px; text-align: center; left: 0px; margin-top: 0px;}
         #homepage-title span {top: 150px;}
         #homepage-title h2 {left: 0px; font-size: 44px; text-align: center; top: 240px; margin-top: 0px;}
         #homepage-title h2 span {font-size: 55px!important;}
         .banner-logo-cwa {top: 50%; margin-right: 0;}
         .progress {display: none;}
         // .progress {padding-left: 0%; height: 150px; width: 100%;}
         // .progress-value {padding-right: 120px;}
         // .anim-typewriter {display: flex; flex-wrap: wrap; font-size: 21px;}


      /** EXPERTISES BLOCK **/
         .expertises {padding: 0px 0 53px;}
         .expertises-container {padding: 5%; width: 90%;}
         .expertises-container h2 {position: relative; font-family: $abril; font-weight: 400; line-height: 1.2; padding-bottom: 0px; margin-bottom: 0px; font-size: 40px;}
         .expertises-list {flex-direction: column;}
         .expertises-list a {width: 100%;}
         .expertises-list .item {width: 90%; height: 375px; margin: 10px auto; padding: 17px;}
         .btn span:not(.btn__icon) {opacity: 1; transform: translateX(-11px);}
         .btn .btn__icon {transform: translate(15px, -50%); opacity: 1; animation-duration: 1.8s; animation-name: clignoter; animation-iteration-count: infinite; transition: none;}
         // .expertises-list .item::before { content: ''; position: absolute; top: 0px; left: -20px; right: -40px; bottom: -20px; z-index: -1; /*background-color: $yellow;*/ opacity: 0; -webkit-transition: opacity .3s; transition: opacity .3s; }
         .expertises-list .item.strategy {background-color: $strategy-color;}
         .expertises-list .item.creation {background-color: $creation-color;}
         .expertises-list .item.print {background-color: $print-color;}
         .expertises-list .item.identity {background-color: $identity-color;}
         .expertises-list .item.seo {background-color: $seo-color;}
         .expertises-list .item.community {background-color: $community-color;}
         .expertises h3 {font-size: 24px;}

      /** PROJECTS BLOCK **/
         .projects {padding: 0px 0px 50px;}
         .projects h2 {font-size: 40px;}
         .projects h3 {font-size: 11px; width: 90%;}
         .projects-container {padding: 5%; width: 90%;}
         .project-intro {height: 200px; width: 100%; margin: 0 auto; margin-left: 0; justify-content: center; font-size: 22vw; letter-spacing: 2px; text-align: center; padding: 0;}
         .projects-grid {flex-direction: column; align-items: center;}
         .projects-grid a {margin: 0 auto;}
         .projects-grid a .project {width: 100%; height: auto;}
         .projects-grid .block-project {padding: 20px; margin: 0;}
         .projects-grid .block-project a img {width: 100%; height: 95vw; transform: scale(0.95);}
         .project-hashtag {font-size: 13px; line-height: 24px;}
         .project-title {font-size: 21px; margin-bottom: 20px; color: #f4ca02;}
         .projects-more {display: flex; justify-content: center; margin: 0 auto; padding: 30px 0;}
         button.learn-more.home .circle.home {background: #f4ca02;}
         button .circle {width: 100%;}
         button.learn-more.home .button-text.home {color: #000;}
         button .circle.home .icon.arrow {background: #000;}
         button .icon.arrow {transform: translate(1rem, 0);}

      /** LAST NEWS BLOCK **/
         .news-container {padding: 5%; width: 90%;}
         .news h2 {font-size: 40px; margin: 0;}
         .news h3 {font-size: 11px; width: 90%;}
         .related-posts-slider {flex-direction: column; align-items: center;}
         .related-posts-slider a.block-news {width: 100%; display: flex; justify-content: center; margin-bottom: 10px;}
         .related-posts-slider .item {width: 80%;}
         .related-posts-slider .item {background-color: #161616; width: calc(80% - 27px);}
         .related-posts-slider .item time {color: rgba(255, 255, 255, 0.6);}
         .related-posts-slider .item:hover time {color: rgba(255, 255, 255, 0.6);}
         .related-posts-slider .item .category {color: rgba(255, 255, 255, 0.6);}
         .related-posts-slider .item:hover .category {color: rgba(255, 255, 255, 0.6);}
         .related-posts-slider .title {font-size: 24px; color: #f4ca02;}
         .related-posts-slider .item .title a {color: #f4ca02;}
         .related-posts-slider .item:hover .title a {color: #f4ca02;}
         .news-btn {justify-content: center; margin: 0;}
         .news-btn .news-more {width: 200px; height: 50px;}
   }
   @media (min-width:1900px) {
      /** BANNER **/
         #homepage-banner {height: calc(100vh - 81px);}
         #homepage-video {width: 80%; height: 87%; margin-top: -50px;}
         #homepage-title h1 {top: 350px;}
         #homepage-title span {top: 550px;}
         .banner-logo-cwa {top: 70%;}
         .progress {top: 70%; padding-left: 45%; width: 60%;}
         .expertises-list {width: 55%;}
         .expertises-container {width: 55%; max-width: 100%;}
         .projects {padding: 42px 0px 150px 0px;}
         .projects-container {width: 55%; max-width: 100%;}
         .projects-grid {width: 55%; max-width: 100%;}
         .project-intro {width: 15%; font-size: 115px;}
         .projects-more {padding-left: 30%;}
         .news {padding: 42px 0% 5% 0%;}
         .news-container {width: 55%; max-width: 100%;}
         .related-posts-slider {width: 55%;}
         .news-btn {margin: 3% 23% 0% 0%;}
         a.news-more {font-size: 27px;}
   }
